import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  fetchReports,
  fetchOneReport,
  addReport,
  updateReport,
  deleteReport,
  resentReport,
  setRecommendation,
  deleteRecommendation,
} from 'actions/reports';
import {
  fetchAllReportsApi,
  fetchOneReportApi,
  addReportApi,
  updateReportApi,
  deleteReportApi,
  resentReportApi,
  recommendationUsageAPI,
} from 'services/reports';
import { addBooking } from 'actions/bookings';
import fetchEntity from './fetch-entity';

const fetchReportsData = fetchEntity.bind(null, fetchReports.actions, fetchAllReportsApi);

const fetchReportData = fetchEntity.bind(null, fetchOneReport.actions, fetchOneReportApi);

const fetchAddReport = fetchEntity.bind(null, addReport.actions, addReportApi);

const fetchUpdateReport = fetchEntity.bind(null, updateReport.actions, updateReportApi);

const fetchDeleteReport = fetchEntity.bind(null, deleteReport.actions, deleteReportApi);

const fetchResentReport = fetchEntity.bind(null, resentReport.actions, resentReportApi);

const fetchSetRecommendation = fetchEntity.bind(
  null,
  setRecommendation.actions,
  recommendationUsageAPI
);

const fetchDeleteRecommendation = fetchEntity.bind(
  null,
  deleteRecommendation.actions,
  recommendationUsageAPI
);

export function* loadFetchReports({ params }) {
  yield call(fetchReportsData, { ...params });
}

export function* loadGetReport({ params }) {
  yield put(fetchOneReport.actions.failure({}, undefined));
  yield call(fetchReportData, params);
}

export function* loadAddReport({ params }) {
  yield call(fetchAddReport, params);
}

export function* loadUpdateReport({ params }) {
  yield call(fetchUpdateReport, params);
}

export function* loadDeleteReport({ params }) {
  yield call(fetchDeleteReport, { ...params });
}

export function* loadResentReport({ params }) {
  yield call(fetchResentReport, { ...params });
}

export function* loadSetRecommendation({ params }) {
  yield call(fetchSetRecommendation, { ...params });
}

export function* loadDeleteRecommendation({ params }) {
  yield call(fetchDeleteRecommendation, { ...params });
}

function* watchFetchReports() {
  yield takeLatest(fetchReports.actionName, loadFetchReports);
}

function* watchAddReport() {
  yield takeLatest(addReport.actionName, loadAddReport);
}

function* watchUpdateReport() {
  yield takeLatest(updateReport.actionName, loadUpdateReport);
}

function* watchDeleteReport() {
  yield takeLatest(deleteReport.actionName, loadDeleteReport);
}

function* watchResentReport() {
  yield takeLatest(resentReport.actionName, loadResentReport);
}

function* watchSetRecommendation() {
  yield takeLatest(setRecommendation.actionName, loadSetRecommendation);
}
function* watchDeleteRecommendation() {
  yield takeLatest(deleteRecommendation.actionName, loadDeleteRecommendation);
}

function* watchGetReport() {
  yield takeLatest(fetchOneReport.actionName, loadGetReport);
}

export function* loadReportsOnChange() {
  const reports = yield select(state => state.reports);
  const id = reports?.selectedReport?.id;
  const { total, skip, limit } = reports;
  if (skip && skip >= total) {
    yield call(fetchReportsData, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
      id,
    });
  } else {
    yield call(fetchReportsData, { $skip: skip, '$sort[updatedAt]': -1, id });
  }
}

function* watchReportsOnChange() {
  yield takeLatest(
    [
      deleteReport.requestTypes.SUCCESS,
      addReport.requestTypes.SUCCESS,
      updateReport.requestTypes.SUCCESS,
      addBooking.requestTypes.SUCCESS,
      resentReport.requestTypes.SUCCESS,
      setRecommendation.requestTypes.SUCCESS,
      deleteRecommendation.requestTypes.SUCCESS,
    ],
    loadReportsOnChange
  );
}

export default {
  watchFetchReports,
  watchAddReport,
  watchGetReport,
  watchUpdateReport,
  watchReportsOnChange,
  watchDeleteReport,
  watchResentReport,
  watchSetRecommendation,
  watchDeleteRecommendation,
};
