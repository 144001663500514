export const API_ROOT = process.env.REACT_APP_API_ROOT || 'https://api.neuralspect.com'; //
export const GOOGLE_MAPS_KEY = 'AIzaSyAhNnpKjb5NiaxDVf5NNXKZxAOD7sKVx8M';
export const GOOGLE_GEOCODEAPI_KEY = 'AIzaSyAhNnpKjb5NiaxDVf5NNXKZxAOD7sKVx8M';
export const APP_VERSION = process.env.REACT_APP_VERSION || '';
export const MOBILE_BREAKPOINT = 992;
export const TINY_MCE_PATH =
  process.env.REACT_APP_TINY_MCE_PATH ||
  'https://nyc3.digitaloceanspaces.com/digilabs/lawvo/tinymce/tinymce.min.js';
export const SYNCFUSION_KEY =
  process.env.REACT_APP_SYNCFUSION_KEY ||
  'Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXdfdXVRRWheVUByWkI=';
export const CLARITY_ID = process.env.REACT_APP_CLARITY_ID || 'pqr1muqjgd';
