import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Form, Modal } from 'antd';
import {
  getGuestContract,
  contractsValuesLoading,
  contractsValuesError,
} from 'selectors/guest-contract';
import { getContract } from 'actions/guest-contract';
import PreviewContent from 'containers/Contracts/PreviewContent';
import { Button } from 'components';
import { updateContractToReport } from 'actions/contracts-to-reports';
import {
  getUpdateContractToReportLoading,
  getUpdateContractToReportError,
  getSuccessState,
} from 'selectors/contracts-to-reports';
import { getBse64Uploads } from 'selectors/templates';
import GuestContractWrapper from './GuestContractWrapper';

const GuestContract = ({ match, history }) => {
  const dispatch = useDispatch();
  const isContractLoading = useSelector(contractsValuesLoading);
  const error = useSelector(contractsValuesError);
  const contractData = useSelector(getGuestContract);
  const updateLoading = useSelector(getUpdateContractToReportLoading);
  const updateError = useSelector(getUpdateContractToReportError);
  const success = useSelector(getSuccessState);
  const base64Uploads = useSelector(getBse64Uploads);

  const [fullName, setFullName] = useState('');
  const [agreement, setAgreement] = useState(false);
  const [status, setStatus] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hideDecline, setHideDecline] = useState(true);

  useEffect(() => {
    dispatch(getContract.request({ token: match.params.token }));
  }, [dispatch, match.params.token]);

  useEffect(() => {
    if (!updateLoading && !updateError && success) {
      if (status) {
        Modal.success({
          title: 'Success',
          content: 'You have successfully signed the contract!',
          onOk() {
            window.location.replace('https://neuralspect.com/');
          },
        });
      } else {
        Modal.info({
          title: 'Contract Declined',
          content: 'Contract was declined!',
          onOk() {
            window.location.replace('https://neuralspect.com/');
          },
        });
      }
    }
    if (!updateLoading && updateError) {
      Modal.warning({
        title: 'Error',
        content: 'Something went wrong',
      });
    }
  }, [updateLoading, updateError, success, status]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isContractLoading && error) {
      if (error.code === 404) return history.push('/404');
      Modal.error({
        title: 'Error',
        content: error.message || 'Form Not Found',
      });
    }
  }, [error, history, isContractLoading]);

  const cancelContract = () => {
    setStatus(false);
    dispatch(
      updateContractToReport.request({
        id: contractData.report.contractStatus.id,
        values: {
          ...contractData.report.contractStatus,
          status: 'decline',
        },
      })
    );
    setIsModalVisible(false);
    setTimeout(() => {
      window.location.replace('https://neuralspect.com/');
    }, 1000);
  };
  const signContract = () => {
    if (
      contractData &&
      contractData.report &&
      contractData.report.contractStatus &&
      contractData.report.contractStatus.id &&
      fullName &&
      base64Uploads &&
      agreement
    ) {
      setStatus(true);
      dispatch(
        updateContractToReport.request({
          id: contractData.report.contractStatus.id,
          values: {
            ...contractData.report.contractStatus,
            status: 'signed',
            fullName,
            signature: base64Uploads,
          },
        })
      );
      setHideDecline(false);
    }
  };

  // eslint-disable-next-line no-shadow
  const contractError = status => {
    Modal.error({
      title: 'Oops... !',
      content: `You are already ${status} contract`,
      onOk() {
        window.location.replace('https://neuralspect.com/');
      },
    });
  };

  useEffect(() => {
    if (contractData?.report?.contractStatus?.status === 'decline') {
      contractError('declined');
    } else if (contractData?.report?.contractStatus?.status === 'signed') {
      contractError('signed');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractData?.report?.status]);

  return (
    <>
      {!isContractLoading && !error && contractData && (
        <GuestContractWrapper>
          <Form layout={'vertical'} labelAlign={'left'} hideRequiredMark>
            <PreviewContent contractData={contractData} setFullName={setFullName} />
            <Checkbox
              style={{ padding: '10px 30px' }}
              value='Agree'
              onChange={e => setAgreement(e.target.checked)}
            >
              I have read, understood and accept the terms of this agreement.{' '}
            </Checkbox>
            <div className='buttonWrapper'>
              {hideDecline && (
                <Form.Item>
                  <Button
                    type='danger'
                    htmlType='submit'
                    className='sign-btn'
                    onClick={() => setIsModalVisible(true)}
                  >
                    Decline
                  </Button>
                </Form.Item>
              )}
              {contractData?.report?.contractStatus?.status !== 'decline' && (
                <Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='sign-btn'
                    disabled={!(fullName && agreement && base64Uploads)}
                    onClick={signContract}
                  >
                    Sign
                  </Button>
                </Form.Item>
              )}
            </div>
          </Form>
        </GuestContractWrapper>
      )}

      <Modal
        centered
        visible={isModalVisible}
        onOk={cancelContract}
        onCancel={() => setIsModalVisible(false)}
      >
        <p>Are you sure want to decline report ?</p>
      </Modal>
    </>
  );
};

export default withRouter(React.memo(GuestContract));
