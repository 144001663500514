import moment from 'moment';

export const getSectionChildrenIds = value => {
  return value.reduce((prev, cur) => {
    if ('child' in cur) {
      return [...prev, ...getSectionChildrenIds(cur.child)];
    }
    if ('fields' in cur) {
      return [...prev, ...getSectionChildrenIds(cur.fields)];
    }
    return [...prev, cur.id];
  }, []);
};

export const sectionFiltration = (child, report, setIsSubSectionExistsOnDocument) => {
  const childrenIds = getSectionChildrenIds(child);
  let shouldHide = true;
  if (report.document && report.document.fields)
    for (const item in childrenIds) {
      if (report.document.fields[childrenIds[item]]) {
        shouldHide = false;
        setIsSubSectionExistsOnDocument(!shouldHide);
        return;
      }
    }
  setIsSubSectionExistsOnDocument(!shouldHide);
};

export const generateRandom = () => Math.round(Math.random() * moment().valueOf());

export const isCondition = label => {
  const fieldLabel = label.trim().toLowerCase();
  return fieldLabel.includes('condition');
};

export const getBase64FromSyncRef = ref => {
  const imageData = ref?.current?.getImageData();
  if (imageData) {
    const canvas = document.createElement('canvas');
    canvas.width = imageData.width;
    canvas.height = imageData.height;
    // Get the 2D rendering context of the canvas
    const context = canvas.getContext('2d');
    // Put the ImageData onto the canvas
    context.putImageData(imageData, 0, 0);
    // Convert the canvas content to a Base64 encoded URL
    return canvas.toDataURL() || '';
  }
  return '';
};

export const blobToBase64 = blob => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};
