import { protectedRouts as routes } from 'constants/routes';

export const roles = {
  ROLE_SUPER_ADMIN: 'super admin',
  ROLE_MANAGER: 'manager',
  ROLE_SINGLE_INSPECTOR: 'single-inspector',
  ROLE_ADMINISTRATOR: 'administrator',
  ROLE_INSPECTOR: 'inspector',
};

export const routePermissions = {
  [roles.ROLE_SUPER_ADMIN]: [
    routes.templates.pathname,
    routes.dashboard.pathname,
    routes.templates.items.edit.absPathname,
    routes.contracts.pathname,
    routes.contracts.items.edit.absPathname,
    routes.contracts.items.preview.absPathname,
    routes.settings.pathname,
    routes.configs.pathname,
    routes.settings.items.profile.pathname,
    routes.settings.items.security.pathname,
    routes.settings.items.accounts.pathname,
    routes.settings.items.thankyouletter.pathname,
    // routes.settings.items.adviceNote.pathname,

    routes.configs.items.inspectionTypes.pathname,
    routes.configs.items.propertyTypes.pathname,
    routes.configs.items.conditions.pathname,
    routes.configs.items.limitations.pathname,
    routes.configs.items.packages.pathname,
    `${routes.settings.items.editAccount.pathname}:id`,
    `${routes.settings.items.accountUsers.pathname}:id`,
  ],
  [roles.ROLE_ADMINISTRATOR]: [
    routes.calendar.pathname,
    routes.contacts.pathname,
    routes.settings.pathname,
    routes.settings.items.security.pathname,
    `${routes.contacts.items.edit.pathname}:id`,
    routes.contacts.items.create.pathname,
    routes.settings.items.profile.pathname,
    routes.brokers.pathname,
    routes.brokers.items.create.pathname,
    `${routes.brokers.items.edit.pathname}:id`,
    routes.reports.pathname,
    routes?.reports?.items?.archive?.pathname,
    routes.reports.items.completed.pathname,
    routes.reports.items.canceled.pathname,
    `${routes.reports.items.edit.pathname}:id`,
    `${routes.reports.items.preview.pathname}:id`,
    routes.invoices.pathname,
    `${routes.invoices.items.edit.pathname}:id`,
  ],
  [roles.ROLE_INSPECTOR]: [
    routes.settings.items.profile.pathname,
    routes.settings.items.security.pathname,
    routes.configuration.pathname,
    routes.reports.pathname,
    routes?.reports?.items?.archive?.pathname,
    routes.reports.items.completed.pathname,
    routes.reports.items.canceled.pathname,
    routes.settings.pathname,
    `${routes.reports.items.edit.pathname}:id`,
    `${routes.editAppReport.pathname}:id`,
    `${routes.reports.items.preview.pathname}:id`,

    routes.configuration.items.propertyTypesUser.pathname,
    routes.configuration.items.inspectionTypesUser.pathname,
    routes.configuration.items.dwellingsUser.pathname,
    // routes.configuration.items.recommendationsUser.pathname,
    routes.configuration.items.limitationsUser.pathname,
    // routes.settings.items.adviceNote.pathname,
  ],
  [roles.ROLE_MANAGER]: [
    routes.calendar.pathname,
    routes.contacts.pathname,
    routes.brokers.pathname,
    routes.settings.pathname,
    routes.configuration.pathname,
    `${routes.contacts.items.edit.pathname}:id`,
    `${routes.brokers.items.edit.pathname}:id`,
    routes.contacts.items.create.pathname,
    routes.brokers.items.create.pathname,
    routes.reports.pathname,
    routes?.reports?.items?.archive?.pathname,
    routes.reports.items.completed.pathname,
    routes.reports.items.canceled.pathname,

    routes.contracts.pathname,
    routes.contracts.items.edit.absPathname,
    routes.contracts.items.preview.absPathname,
    `${routes.reports.items.edit.pathname}:id`,
    `${routes.reports.items.preview.pathname}:id`,
    `${routes.reports.items.pdf.pathname}:id`,
    routes.invoices.pathname,
    `${routes.invoices.items.edit.pathname}:id`,
    routes.templates.items.edit.pathname,
    routes.settings.items.profile.pathname,
    routes.settings.items.users.pathname,
    routes.settings.items.billings.pathname,
    routes.settings.items.security.pathname,

    routes.settings.items.thankyouletter.pathname,

    routes.configuration.items.services.pathname,
    routes.configuration.items.propertyTypesUser.pathname,
    routes.configuration.items.inspectionTypesUser.pathname,
    routes.configuration.items.dwellingsUser.pathname,
    // routes.configuration.items.recommendationsUser.pathname,
    routes.configuration.items.limitationsUser.pathname,
    routes.configuration.items.summary.pathname,
  ],
  [roles.ROLE_SINGLE_INSPECTOR]: [
    routes.calendar.pathname,
    routes.contacts.pathname,
    routes.brokers.pathname,
    routes.settings.pathname,
    routes.configuration.pathname,
    `${routes.contacts.items.edit.pathname}:id`,
    `${routes.brokers.items.edit.pathname}:id`,
    routes.contacts.items.create.pathname,
    routes.brokers.items.create.pathname,
    routes.contracts.pathname,
    routes.contracts.items.edit.absPathname,
    routes.contracts.items.preview.absPathname,
    routes.reports.pathname,
    routes?.reports?.items?.archive?.pathname,
    routes.reports.items.completed.pathname,
    routes.reports.items.canceled.pathname,
    `${routes.reports.items.edit.pathname}:id`,
    `${routes.reports.items.preview.pathname}:id`,
    `${routes.reports.items.pdf.pathname}:id`,
    `${routes.editAppReport.pathname}:id`,
    routes.invoices.pathname,
    `${routes.invoices.items.edit.pathname}:id`,
    routes.templates.items.edit.pathname,
    routes.settings.items.profile.pathname,
    routes.settings.items.billings.pathname,
    routes.settings.items.security.pathname,
    routes.settings.items.thankyouletter.pathname,

    routes.configuration.items.services.pathname,
    routes.configuration.items.propertyTypesUser.pathname,
    routes.configuration.items.inspectionTypesUser.pathname,
    routes.configuration.items.dwellingsUser.pathname,
    // routes.configuration.items.recommendationsUser.pathname,
    routes.configuration.items.limitationsUser.pathname,
    routes.configuration.items.summary.pathname,
    // routes.settings.items.adviceNote.pathname,
  ],
};
