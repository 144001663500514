import React from 'react';
import styled, { css } from 'styled-components';
import { Upload as upload, Spin, Icon } from 'antd';
import { Notification } from 'components';
import Compressor from 'compressorjs';
import VideoPlayer from '../VideoPlayer';

const Upload = styled(upload)`
  .ant-upload-select-picture-card {
    display: flex;
    height: 35px;
    width: 35px;
  }

  ${props =>
    props.hideUpload &&
    css`
      .ant-upload-select-picture-card {
        display: none;
      }
    `}
`;

const UploadVideo = styled(upload)`
  .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
    div {
      flex-basis: 33.33%;
      @media (max-width: 992px) {
        flex-basis: 100%;
      }
    }
  }
  ${props =>
    props.hideUpload &&
    css`
      .ant-upload-select-picture-card {
        display: none;
      }
    `}
`;

const MediaUpload = ({
  fileList = [],
  changeHandler,
  loading,
  extensions,
  maxAmount,
  multiple,
  onPreview,
  fileSize = 150,
  hideUpload,
  setImage,
  setIsVisible,
  files,
  otherFiles,
  ...otherProps
}) => {
  const [videoModalVisible, setVideoModalVisible] = React.useState(false);
  const [selectedVideo, setSelectedVideo] = React.useState(null);

  const imgMimeType = ['image/jpeg', 'image/jpg', 'image/png'];
  const documetFileExt = ['pdf', 'docx', 'xls', 'xlsx'];
  const videoFileExt = ['avi', 'mp4', 'flv', 'WMV', 'WEBM', 'mov'];
  const generateVideoThumbnail = file =>
    new Promise(resolve => {
      const video = document.createElement('video');
      video.src = URL.createObjectURL(file);
      video.currentTime = 1; // capture frame at 1 second
      video.onloadeddata = () => {
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        resolve(canvas.toDataURL('image/png'));
      };
    });
  const addFile = (file, files) => {
    Promise.all(
      files.map(async f => {
        // eslint-disable-next-line no-unused-expressions
        if (imgMimeType.includes(f.type)) {
          return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-new
            new Compressor(f, {
              quality: f.size > 2 ? 0.2 : 0.6,
              success(result) {
                resolve(result);
              },
              error(err) {
                reject(err);
              },
            });
          });
        }
        if (f.type.startsWith('video/')) {
          f.thumbUrl = await generateVideoThumbnail(f);
        }
        return f;
      })
    ).then(
      selectedFileList => {
        // const validExtension = selectedFileList?.some(ex => allowExt.includes(ex.type));
        const hasBigFiles = selectedFileList.find(f => f.size > fileSize * 1024 * 1024);
        if (
          (!multiple && fileList.length >= 1) ||
          fileList.length + selectedFileList.length > maxAmount ||
          hasBigFiles
        ) {
          Notification.warning(
            `Maximum ${maxAmount || (!multiple && 1)} files allowed, ${fileSize} MB each`
          );
        } else {
          changeHandler &&
            changeHandler(selectedFileList, [...fileList, ...selectedFileList], 'add');
        }
      },
      reason => {
        Notification.warning(reason);
      }
    );

    return false;
  };

  const deleteFile = file => {
    changeHandler &&
      changeHandler(
        file,
        fileList.filter(f => f.uid !== file.uid),
        'delete'
      );
  };

  const handlePreview = e => {
    const isVideo = videoFileExt?.some(ex => e?.name?.toLowerCase()?.includes(ex.toLowerCase()));
    const isDocument = documetFileExt?.some(ex =>
      e?.name?.toLowerCase()?.includes(ex.toLowerCase())
    );
    if (isVideo) {
      setSelectedVideo(e);
      setVideoModalVisible(true);
    } else if (isDocument) {
      window.open(e.url, '_blank');
    } else {
      setImage(e);
      setIsVisible(true);
    }
  };

  return (
    <>
      <Spin spinning={!!loading}>
        <UploadVideo
          listType='text'
          fileList={otherFiles}
          onPreview={handlePreview}
          beforeUpload={addFile}
          onRemove={deleteFile}
          hideUpload={hideUpload}
          multiple={multiple}
          showUploadList
          {...otherProps}
        />
        <Upload
          listType='picture-card'
          fileList={files}
          onPreview={handlePreview}
          beforeUpload={addFile}
          onRemove={deleteFile}
          hideUpload={hideUpload}
          multiple={multiple}
          showUploadList
          {...otherProps}
        >
          <div>
            <div className='ant-upload-text'>
              <Icon type='camera' style={{ fontSize: '15px', margin: '0px', color: '#D9D9D9' }} />
            </div>
          </div>
        </Upload>
      </Spin>

      <VideoPlayer
        video={selectedVideo}
        visible={videoModalVisible}
        onClose={() => setVideoModalVisible(false)}
        loading={loading}
      />
    </>
  );
};

export default React.memo(MediaUpload);
