import client from './main-client';

export const fetchAllReportsApi = params => client().get('/reports', { params });
export const fetchOneReportApi = ({ id, ...params }) => {
  return client().get(`/reports/${id}`, { params });
};
export const updateReportApi = params =>
  client().put(`/reports/${params.id}`, { ...params.values });
export const addReportApi = data => client().post('/reports', data);
export const deleteReportApi = params => client().delete(`/reports/${params.id}`, { params });
export const resentReportApi = params =>
  client().patch(`/reports/${params.id}`, { ...params.values });

export const recommendationUsage = params =>
  client().patch(`/recommendationUsage`, { ...params.values });
export const fetchAllRecommendationUsage = params =>
  client().get(`/recommendationUsage`, { params: { ...params } });

export const recommendationUsageAPI = params =>
  client().patch(`/recommendationUsage`, { ...params.values });
